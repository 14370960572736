import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Form, Button, Spinner, Row } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function Login() {

  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  //----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const auth_result = await auth.loginAction(username, password);

    if (!auth_result['result']) {
      if (auth_result['detail'] === 'Unverified account.') navigate('/verify-email');
      setMessage(auth_result['detail']);
    } else {
      setMessage(`Welcome back. Redirecting to your dashboard...`);
      navigate("/dashboard/", { replace: true });
    }
    setTimeout(() => {setMessage("")}, 5000);
    setLoading(false);
  };

  //----------------------------------------------------------------

  return(
    <Row className="align-items-center justify-content-center" xs={1}>
      <Col sm={6}>
        <Form onSubmit={handleSubmit}>

          <h3>Please Log In</h3>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              Username
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={e => setUsername(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              Password
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="password"
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Form.Group>

          <Button
            className="w-100"
            type="submit"
            disabled={loading}
          >
            {loading ? <>
              <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
            />{' '}
            Working...
            </> : "Submit"}
          </Button>
        </Form>
      </Col>

      <Col className="text-center">
        {message}
      </Col>
    </Row>
  )
}

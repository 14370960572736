import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';

import TagButton from '../common/tag-button';
import BasicSpinner from '../common/basic-spinner';
import { formatTagsJSON } from '../../utils/utils';

//================================================================

export default function UpdateFormTags({object, onSubmit, disabled}){

  const [tagString, setTagString ] = useState('');
  const [tagDisplay, setTagDisplay] = useState(object.tags);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [working, setWorking] = useState(disabled);

  useEffect(() => {
    setTagDisplay(object.tags);
  },[object]);

  const addTagToRemove = useCallback((tag) => {
    const copy = tagsToRemove.slice();
    copy.push(tag);
    copy.filter(e => e);
    setTagsToRemove([...new Set(copy)]);
  }, [tagsToRemove]);

  const removeTagToRemove = useCallback((tag) => {
    const copy = tagsToRemove.slice();
    const index = tagsToRemove.findIndex(e => e === tag );
    if (index >=0 ) copy.splice(index, 1);
    copy.filter(e => e);
    setTagsToRemove([...new Set(copy)]);
  }, [tagsToRemove]);

  const addTags = useCallback(async () => {
    if (!tagString) return null;
    setWorking(true);
    await onSubmit(
      {"add_tags": formatTagsJSON(tagString)},
      object._id
    )
    .then((result) => { if (result) setTagString('') });
    setWorking(false);
  }, [tagString, object, onSubmit]);

  const removeTags = useCallback(async() => {
    if (tagsToRemove.length === 0) return null;
    setWorking(true);
    await onSubmit(
      {"remove_tags": tagsToRemove},
      object._id
    )
    .then((result) => { if (result) setTagsToRemove([]) });
    setWorking(false);
  }, [tagsToRemove, object, onSubmit])

  return(
    <Form.Group className="mb-4">
      <Form.Label>Tags</Form.Label>
      <Row className="g-1 justify-content-start mb-2">
        {tagDisplay.map((tag,index) => 
          <TagButton
            key={`${index}-${tag}`}
            tag={tag}
            disabled={working}
            onSelect={addTagToRemove}
            onDeselect={removeTagToRemove}
          />
        )}
        <Col>
          <Button
            className="text-nowrap"
            onClick={() => removeTags()}
          >
            {working ?
              <BasicSpinner text={'Updating...'} />
              :
              "Remove Selected"
            }
          </Button>
        </Col>
      </Row>
      <Row className="g-0 justify-content-between">
        <Col className="col-10 text-start">
          <Form.Control
            value={tagString}
            type="text"
            placeholder="Enter tags"
            onChange={(event) => setTagString(event.target.value)}
            disabled={working}
          />
        </Col>
        <Col className="col-2 text-end">
          <Button
            className="text-nowrap rounded"
            variant="primary"
            onClick={addTags}
            disabled={working}
          >
            {working ?
              <BasicSpinner />
              :
              <i className="bi bi-plus-square" />
          }
          </Button>
        </Col>
      </Row>
      <Form.Text className="text-muted">
        Separate tags using commas.
      </Form.Text>
    </Form.Group>
  );
}

import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import ListView from '../common/list-view';

import ItemDetails from '../item/item-details';

import { fetchPublicItems, fetchPublicItemParents, fetchPublicItemChildren } from '../../utils/fetch-helpers';
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function ItemPage(){

  const user = useAuth();
  const params = useParams();

  const [parents, setParents] = useState(null);
  const [items, setItems] = useState(null);
  const [item, setItem] = useState(null);

  const [itemImages, setItemImages] = useState(null);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchItems = async (signal) => {
    setItems(await fetchPublicItems(signal));
  };

  //----------------------------------------------------------------

  const fetchItemImages = async (item, signal) => {
    if (item){
      const fetchResult = await fetchPublicItemChildren(item._id, signal);
      if (fetchResult) {
        setItemImages(fetchResult.images)
      } else {
        setItemImages(null);
      };
    }
  };

  //----------------------------------------------------------------

  const fetchParents = async (item, signal) => {
    if(!item) return;
    setParents( await fetchPublicItemParents(item._id, signal));
  };

  //----------------------------------------------------------------

  const setCurrentItem = async (itemId) => {
    if (!itemId || !items) return;
    setLoading(true);
    const index = await items.findIndex((element) => element._id === itemId);
    if (index >= 0){
      items[index].index = index;
      setItem(items[index]);
    }
  };

  //----------------------------------------------------------------

  useEffect(() => {
    if (items) setCurrentItem(params.itemId);
  }, [items, params.itemId]);

  //----------------------------------------------------------------

  useEffect(() => {
    if(item){
      const abortController = new AbortController();
      const signal = abortController.signal;

      setLoading(true);

      (async () => {
        await fetchParents(item, signal);
        await fetchItemImages(item, signal);
        setLoading(false);
      })();

      return () => {
        // Cancel the request when the component unmounts
        abortController.abort();
      };
    }
  }, [item]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    fetchItems(signal);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={items}
        currentObject={item}
        onClick={setCurrentItem}
        basePath={`/item`}
      />

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <ItemDetails item={item}/>
      }

      <Row className="mb-2 align-items-center justify-content-center" md={2}>
        <Col className="mb-2">
          <Link to={`/public#items`}>
            <Button className="text-nowrap w-100">
              Back to Items
            </Button>
          </Link>
        </Col>
        {user && item &&
          <Col className="mb-2">
            <Link
              to={`/dashboard/item/${item._id}`}
            >
              <Button
                className="text-nowrap w-100"
              >
                View Your Item
              </Button>
            </Link>
          </Col>
        }
      </Row>

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>

          {/* Images Section */}
          {itemImages && itemImages.length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Images</Col>
              </Row>
              <ListView
                list={itemImages}
                type="image"
              />
            </>
          }
          {/* End Image Section */}

          {/* Parents Section*/}
          { parents && parents['collections'].length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Parents</Col>
              </Row>

              <ListView
                list={parents['collections']}
                name="Collections"
                type="collection"
              />
            </>
          }
          {/* End Parents Section*/}

        </>
      }

    </>
  );
}

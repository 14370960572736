import { useParams, Link } from "react-router-dom";

import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import ListView from '../common/list-view';

import ImageDetails from '../image/image-details';

import { fetchPublicImages, fetchPublicImageParents } from '../../utils/fetch-helpers';
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function ImagePage(){

  const user = useAuth();
  const params = useParams();

  const [parents, setParents] = useState(null);
  const [images, setImages] = useState(null);
  const [image, setImage] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchImages = useCallback( async (signal) => {
    setImages(await fetchPublicImages(signal));
  });

  //----------------------------------------------------------------

  const fetchParents = useCallback(async (signal) => {
    if (image) setParents(
      await fetchPublicImageParents(image._id, signal)
    );
  });

  //----------------------------------------------------------------

  const setCurrentImage = useCallback( async (imageId) => {
    if (!imageId || !images) return;
    setLoading(true);
    const index = await images.findIndex((candidate) => candidate._id === imageId);
    if (index >= 0){
      images[index].index = index;
      setImage(images[index]);
    }
  }, [images]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (images) setCurrentImage(params.imageId);
  }, [images, params.imageId]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (image){
      const abortController = new AbortController();
      const signal = abortController.signal;

      setLoading(true);

      (async () => {
        await fetchParents(signal);
        setLoading(false);
      })();

      return () => {
        // Cancel the request when the component unmounts
        abortController.abort();
      };
    }
  }, [image]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    fetchImages(signal)

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={images}
        currentObject={image}
        onClick={setCurrentImage}
        basePath={`/image`}
      />

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <ImageDetails image={image}/>
      }

      <Row className="mb-2 align-items-center justify-content-center" md={2}>
        <Col className="mb-2">
          <Link to={`/public#images`}>
            <Button className="w-100 text-nowrap">
              Back to Images
            </Button>
          </Link>
        </Col>
        {user && image &&
          <Col className="mb-2">
            <Link
              to={`/dashboard/image/${image._id}`}
            >
              <Button
                className="text-nowrap w-100"
              >
                View Your Image
              </Button>
            </Link>
          </Col>
        }
      </Row>

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          {/* Parents Section*/}
          { parents &&
            <>
              <hr />

              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Parents</Col>
              </Row>

              <ListView
                list={parents['collections']}
                name="Collections"
                type="collection"
              />

              <ListView
                list={parents['items']}
                name="Items"
                type="item"
              />
            </>
          }
          {/* End Parents Section*/}

        </>
      }
    </>
  );
}

import { Col, Row, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

//================================================================
 
export default function NoPage() {
  return (
    <Row className="align-items-center justify-content-center" xs={1}>
      <Col className="h4 mb-4 text-center">
          404 - NOT FOUND
      </Col>
      <Col className="mb-4 text-center">
          Unable to find the resource you requested.
      </Col>
      <Col className="mb-4 text-center" sm={6} md={4}>
        <Link to="/">
          <Button className="w-100">
           Home
          </Button>
        </Link>
      </Col>
    </Row>
  );
}

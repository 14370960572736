import { Col, Row, Tab , Tabs } from 'react-bootstrap';
import { useLocation} from "react-router-dom";
import { useState, useEffect } from 'react';

import BasicSpinner from '../components/common/basic-spinner';
import InfiniteScroll from '../components/common/infinite-scroll';

import { fetchPublicCollections, fetchPublicItems, fetchPublicImages } from '../utils/fetch-helpers';

//================================================================

export default function Public() {

  const { hash } = useLocation();

  const [collections, setCollections] = useState();
  const [items, setItems] = useState();
  const [images, setImages] = useState();
  const [key, setKey] = useState('collections');

  //----------------------------------------------------------------

  const fetchCollections = async (signal) => {
    setCollections(await fetchPublicCollections(signal));
  };

  //----------------------------------------------------------------

  const fetchItems = async (signal) => {
    setItems(await fetchPublicItems(signal));
  };

  //----------------------------------------------------------------

  const fetchImages = async (signal) => {
    setImages(await fetchPublicImages(signal));
  };

  //----------------------------------------------------------------

  const setActiveKeyFromHash = (hash) => {
    switch(hash){
      case '#collections':
      case '#items':
      case '#images':
        setKey(hash.replace('#',''));
        break;
      default:
        break;
    }
  };

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchCollections(signal);
    fetchItems(signal);
    fetchImages(signal);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  useEffect(()=>{
    setActiveKeyFromHash(hash);
  },[hash])

  //----------------------------------------------------------------

  return (
    <>
      <Row className="mb-2">
        <Col className="h4">
          Public Entries
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        {/*Tab for Collections*/}
        <Tab eventKey="collections" title="Collections">
          {!collections ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={collections}
              type="collection"
              isPrivate={false}
            />
          }
        </Tab>

        {/*Tab for Items*/}
        <Tab eventKey="items" title="Items">
          {!items ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={items}
              type="item"
              isPrivate={false}
            />
          }
        </Tab>

        {/*Tab for Images*/}
        <Tab eventKey="images" title="Images">
          {!images ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={images}
              type="image"
              isPrivate={false}
            />
          }
        </Tab>
      </Tabs>
    </>
  );
}

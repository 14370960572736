import { useContext, createContext, useState, useEffect, useCallback } from "react";

//================================================================

const AuthContext = createContext();

//----------------------------------------------------------------

export default function AuthProvider({ children }) {

  //grab locally stored user and access token data
  const [user, setUser] = useState(localStorage.getItem("plasticene_user") || null);
  const [token, setToken] = useState(localStorage.getItem("plasticene_token") || null);

  //----------------------------------------------------------------

  // helper to clear local storage and in-memory values
  const clearStorage = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("plasticene_user");
    localStorage.removeItem("plasticene_token");
  }

  const fetchToken = async (username, password) => {
  
    // fastAPI authorization using the Oauth2PasswordRequestForm
    let formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    const authResult = await fetch('/api/token', {
      method: 'POST',
      body: formData
    })
    .then(async (response) => {
      let result = {};
      if (response.ok){
        result['result'] = true;
        result['token'] = await response.json();
      } else if (response.status === 500) {
        result['result'] = false;
        result['detail'] = 'A server error occured.';
      } else if (response.status === 400) {
        result['result'] = false;
        const { detail } = await response.json();
        result['detail'] = detail;
      } else {
        result['result'] = false;
        result['detail'] = 'An unexpected error occured.';
      }
      return result;
    })
    .catch((error) => console.error(error));

    return authResult;
  }

  const fetchAuthorizedUser = async (access_token) => {
    if (!access_token) return null;

    const authorizedUser = await fetch('/api/me', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    .then(async (response) => {
      let result = {};
      if (response.ok){
        result['result'] = true;
        result['user'] = await response.json();
      } else if (response.status === 500) {
        result['result'] = false;
        result['detail'] = 'A server error occured.';
      } else if (response.status === 400) {
        result['result'] = false;
        const { detail } = await response.json();
        result['detail'] = detail;
      } else {
        result['result'] = false;
        result['detail'] = 'An unexpected error occured.';
      }
      return result;
    })
    .catch((error) => console.error(error));

    return authorizedUser;
  };

  const loginAction = async (username, password) => {
    const fetchTokenResult = await fetchToken(username, password);

    if (!fetchTokenResult['result']) return fetchTokenResult;

    const access_token = fetchTokenResult['token'].access_token;

    await setToken(access_token);
    localStorage.setItem("plasticene_token", access_token);

    const fetchAuthorizedUserResult = await fetchAuthorizedUser(access_token);

    if (!fetchAuthorizedUserResult['result']) return fetchAuthorizedUserResult;

    const authorizedUser = fetchAuthorizedUserResult['user'];

    await setUser(authorizedUser);
    localStorage.setItem("plasticene_user", authorizedUser);

    return fetchAuthorizedUserResult;
  };

  const logOut = () => {
    clearStorage();
  };

  const authCheck = async () => {
    const result = await fetchAuthorizedUser(token);
    return result ? result['result'] : false;
  }

  //----------------------------------------------------------------

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut, authCheck }}>
      {children}
    </AuthContext.Provider>
  );

};

//----------------------------------------------------------------

export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth for Fast API

//https://fastapi.tiangolo.com/advanced/security/http-basic-auth/
//https://fastapi.tiangolo.com/tutorial/security/first-steps/

//Sessions and Cookies
//https://medium.com/@pradoshkumar.jena/session-based-authentication-with-fastapi-a-step-by-step-guide-ca19e98ce0f9

// Authentication for React

// https://www.robinwieruch.de/react-router-private-routes/
// https://dev.to/sharmaprash/a-guide-to-creating-protected-routes-with-react-router-dom-45f4
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// https://ui.dev/react-router-protected-routes-authentication
// https://blog.logrocket.com/authentication-react-router-v6/
// https://stackoverflow.com/questions/62378796/cannot-destructure-property-of-object-from-context

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AuthProvider from "./utils/auth-provider";
import PrivateRoute from "./routes/private-route";

import Dashboard from "./routes/dashboard";
import Public from "./routes/public";
import Layout from "./routes/layout";
import Home from "./routes/home";
import Login from "./routes/login";
import NoPage from "./routes/no-page";
import SignUp from "./routes/sign-up";
import VerifyEmail from "./routes/verify-email";

import PublicCollectionPage from './components/public/public-collection-page'
import PublicCollectionsPage from './components/public/public-collections-page'
import PublicItemPage from './components/public/public-item-page';
import PublicItemsPage from './components/public/public-items-page';
import PublicImagePage from './components/public/public-image-page';
import PublicImagesPage from './components/public/public-images-page';

import CollectionPage from './components/collection/collection-page'
import CollectionsPage from './components/collection/collections-page'
import ItemPage from './components/item/item-page';
import ItemsPage from './components/item/items-page';
import ImagePage from './components/image/image-page';
import ImagesPage from './components/image/images-page';

export default function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes forceRefresh={true}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="*" element={<NoPage />} />
              <Route path="login" element={<Login />} />
              <Route path="public" element={<Public />} />
              <Route path="sign-up" element={<SignUp />} />
              {/*<Route path="password-reset" element={<PasswordReset />} />*/}
              <Route path="verify-email" element={<VerifyEmail />} />
              {/*<Route path="images" element={<PublicImagesPage />} />*/}
              <Route path="image/:imageId" element={<PublicImagePage />} />
              {/*<Route path="items" element={<PublicItemsPage />} />*/}
              <Route path="item/:itemId" element={<PublicItemPage />} />
              {/*<Route path="collections" element={<PublicCollectionsPage />} />*/}
              <Route path="collection/:collectionId" element={<PublicCollectionPage />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="*" element={<NoPage />} />
                <Route path="images" element={<ImagesPage />} />
                <Route path="image/:imageId" element={<ImagePage />} />
                <Route path="items" element={<ItemsPage />} />
                <Route path="item/:itemId" element={<ItemPage />} />
                <Route path="collections" element={<CollectionsPage />} />
                <Route path="collection/:collectionId" element={<CollectionPage />} />
              </Route>
            </Route>

          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

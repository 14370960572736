import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import ModalShell from '../common/modal-shell';
import BasicSpinner from '../common/basic-spinner';
import InfiniteScroll from '../common/infinite-scroll';

import CollectionsInfo from './collections-info';
import CollectionCreateForm from './collection-create-form';

import { useAuth } from "../../utils/auth-provider";
import { fetchUserCollections } from '../../utils/fetch-helpers'

//================================================================

export default function CollectionsPage(){

  const user = useAuth();

  const [collections, setCollections] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const addCollection = (collection) => {
    setCollections([...collections, collection]);
    setModalShow(false);
  };

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      setCollections(await fetchUserCollections(user, signal));
      setLoading(false);
    })();

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 text-start">Collections:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setModalShow(true)}
              >
                Create New Collection
              </Button>
            </Col>
          </Row>
          <InfiniteScroll
            data={collections}
            type="collection"
            isPrivate
          />
        </>
      }

      <ModalShell
        title="Start New Collection"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <CollectionCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addCollection}
        />
      </ModalShell>
    </>
  );
}

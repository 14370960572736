const abortErrorMessage = "Fetch aborted by user action (browser stop button, closing tab, etc.)";

//================================================================
// Core Functions
//----------------------------------------------------------------
// makeFetchParams

// Faciliates settings fetch parameters like signal, headers, and method.
function makeFetchParams(signal, accessToken, method){
  const fetchParams = {}
  fetchParams['method'] = method || 'GET';
  fetchParams['headers'] = { "Content-Type": "application/json" };
  if (signal) fetchParams['signal'] = signal;
  if (accessToken) fetchParams.headers['Authorization'] = `Bearer ${accessToken}`;
  return fetchParams;
}

//----------------------------------------------------------------
// doFetch

// Workhorse for GET API operations
async function doFetch(apiEndpoint, signal, accessToken){

  const fetchParams = makeFetchParams(signal, accessToken);

  return await fetch( apiEndpoint, fetchParams)
  .then((response) => {
    if (response.ok) return response.json();
  })
  .catch((error) => {
    if (error.name === "AbortError") {
      console.error(abortErrorMessage);
    } else {
      console.error(error);
    }
  });
}

//----------------------------------------------------------------
// doDelete

// Workhorse for DELETE API operations
async function doDelete(apiEndpoint, signal, accessToken){

  const fetchParams = makeFetchParams(signal, accessToken, 'DELETE');

  return await fetch( apiEndpoint, fetchParams)
  .then((response) => {return (response.status === 204 || response.status === 404)})
  .catch((error) => {
    if (error.name === "AbortError") {
      console.error(abortErrorMessage);
    } else {
      console.error(error);
    }
  });
}

//================================================================
// PUBLIC
//----------------------------------------------------------------
// Collections

export async function fetchPublicCollections(signal){
  return await doFetch(
    `/api/public/collections`,
    signal
  );
}

//----------------------------------------------------------------
// Collection Parents

export async function fetchPublicCollectionParents(collectionId, signal){
  return await doFetch(
    `/api/public/collection/${collectionId}/parents`,
    signal
  ) || {'collections' : []};
}

//----------------------------------------------------------------
// Collection Children

export async function fetchPublicCollectionChildren(collectionId, signal){
  return await doFetch(
    `/api/public/collection/${collectionId}/children`,
    signal
  ) || {
    'collections' : [],
    'items' : [],
    'images' : []
  };
}

//----------------------------------------------------------------
// Items

export async function fetchPublicItems(signal){
  return await doFetch(
    `/api/public/items`,
    signal
  ) || [];
}

//----------------------------------------------------------------
// Item Parents

export async function fetchPublicItemParents(itemId, signal){
  return await doFetch(
    `/api/public/item/${itemId}/parents`,
    signal
  ) || {'collections' : []};
}

//----------------------------------------------------------------
// Item Children

export async function fetchPublicItemChildren(itemId, signal){
  return await doFetch(
    `/api/public/item/${itemId}/children`,
    signal
  ) || {'images' : []};
}

//----------------------------------------------------------------
// Images

export async function fetchPublicImages(signal){
  return await doFetch(
    `/api/public/images`,
    signal
  ) || [];
}

//----------------------------------------------------------------
// Image Parents

export async function fetchPublicImageParents(imageId, signal){
  return await doFetch(
    `/api/public/image/${imageId}/parents`,
    signal
  ) || {
    'collections' : [],
    'items' : []
  };
}

//================================================================
// PRIVATE
//----------------------------------------------------------------
// User Collections

export async function fetchUserCollections(user, signal){
  if (!user) return [];
  return await doFetch(
    `/api/collection/`,
    signal,
    user.token
  ) || [];
}

//----------------------------------------------------------------
// User Collection Parents

export async function fetchUserCollectionParents(user, collectionId, signal){
  if (!user) return;
  return await doFetch(
    `/api/collection/${collectionId}/parents`,
    signal,
    user.token
  )  || {'collections' : []};
}

//----------------------------------------------------------------
// User Collection Children

export async function fetchUserCollectionChildren(user, collectionId, signal){
  if (!user) return;
  return await doFetch(
    `/api/collection/${collectionId}/children`,
    signal,
    user.token
  ) || {
    'collections' : [],
    'items' : [],
    'images' : []
  };
}

//----------------------------------------------------------------
// Delete User Collection

export async function deleteUserCollection (user, collectionId, signal){
  if (!user) return;
  return await doDelete(
    `/api/collection/${collectionId}`,
    signal,
    user.token
  );
}

//----------------------------------------------------------------
// User Items

export async function fetchUserItems(user, signal){
  if (!user) return;
  return await doFetch(
    `/api/item/`,
    signal,
    user.token
  ) || [];
}

//----------------------------------------------------------------
// User Item Parents

export async function fetchUserItemParents(user, itemId, signal){
  if (!user) return;
  return await doFetch(
    `/api/item/${itemId}/parents`,
    signal,
    user.token
  ) || {'collections' : []};
}

//----------------------------------------------------------------
// User Item Images

export async function fetchUserItemImages(user, itemId, signal){
  if (!user) return;
  return await doFetch(
    `/api/item/${itemId}/children`,
    signal,
    user.token
  ) || {'images' : []};
}

//----------------------------------------------------------------
// Delete User Item

export async function deleteUserItem(user, itemId, signal){
  if (!user) return;
  return await doDelete(
    `/api/item/${itemId}`,
    signal,
    user.token
  );
}

//----------------------------------------------------------------
// User Images

export async function fetchUserImages(user, signal){
  if (!user) return;
  return await doFetch(
    `/api/image/`,
    signal,
    user.token
  ) || [];
}

//----------------------------------------------------------------
// User Image Parents

export async function fetchUserImageParents(user, imageId, signal){
  if (!user) return;
  return await doFetch(
    `/api/image/${imageId}/parents`,
    signal,
    user.token
  ) || {
    'collections' : [],
    'items' : []
  };
}

//----------------------------------------------------------------
// Delete User Image

export async function deleteUserImage (user, imageId, signal){
  if (!user) return;
  return await doDelete(
    `/api/image/${imageId}`,
    signal,
    user.token
  );
}

//================================================================
//https://stackoverflow.com/questions/47195119/how-to-capture-filereader-base64-as-variable

async function getBase64(blob) {
  return new Promise(function(resolve, reject) {
    var reader = new FileReader();
    reader.onload = function() { resolve(reader.result); };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

//----------------------------------------------------------------

export async function fetchImageData(imageId, width, height, accessToken, signal) {
  if (!imageId) return;

  const fetchParams = makeFetchParams(signal, accessToken);

  const heightParam = height ? `height=${height}` : "";
  const widthParam = width ? `width=${width}` : "";

  const imageBase64 = await fetch(`/api${accessToken ? '' : '/public'}/image/${imageId}/file?${widthParam}&${heightParam}`,
    fetchParams
  )
  .then((response) => {
    if(response.ok) return response.blob();
    return null
  })
  .then(async (blob) => {
    if (blob instanceof Blob){
      return await getBase64(blob);
    }
  })
  .catch((error) => {
    if (error.name === "AbortError") {
      console.error(abortErrorMessage);
    } else {
      console.error(error);
    }
  });

  return imageBase64;
}

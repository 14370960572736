import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Container, Breadcrumb, Row } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";
import MainNav from '../components/nav-bars/main-nav'

//================================================================

export default function Layout(){

  const user = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <MainNav />
      <Container fluid="md">

        <Outlet />

        <hr className="mb-3" />

        <Row>
          <Breadcrumb as="nav" className="d-flex justify-content-center">
            <Link to="/">
              Home
            </Link>&nbsp;|&nbsp;
            <Link to="/public">
              Public Entries
            </Link>&nbsp;|&nbsp;
            {user.token ?
              <>
                <Link to="/dashboard/">
                  Dashboard
                </Link>&nbsp;|&nbsp;
                <Link onClick={() => user.logOut()}>
                  Sign out
                </Link>
              </>
              :
              <>
                <Link to="/login">
                  Sign in
                </Link>&nbsp;|&nbsp;
                <Link to="/sign-up">
                  Sign up
                </Link>
              </>
            }
          </Breadcrumb>
        </Row>

      </Container>
    </>
  )
};

//adapted from https://www.geeksforgeeks.org/implementing-pagination-and-infinite-scrolling-with-react-hooks/

import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import ListView from '../common/list-view';

export default function InfiniteScroll({data, type, pageSize, isPrivate}) {

  const itemsPerPage = pageSize || 20;
  const [page, setPage] = useState(1);
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moreData, setMoreData] = useState(true);

  useEffect(() => {

    if (!data || data.length === 0) return;

    const loadMoreData = async () => {
      setLoading(true);

      let newData = data.slice((page-1) * itemsPerPage, page*itemsPerPage);

      if (Array.isArray(newData) && newData.length > 0) {
        setLoadedData((prevData) => {
          if(JSON.stringify(prevData) !== JSON.stringify(newData)){
            return [...prevData, ...newData];
          } else {
            return prevData;
          }
        });
      } else {
        setMoreData(false);
      }
      setLoading(false);
    };

    if (moreData && !loading) {
      loadMoreData();
    }

  }, [data, page, itemsPerPage, moreData, loading]);

  const handleScroll = () => {
    if (
      window.innerHeight +
      document.documentElement.scrollTop >=
      document.documentElement.offsetHeight * 0.9
    ) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () =>
      window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Col>
      <ListView
        key={type}
        list={loadedData}
        type={type}
        isPrivate={isPrivate}
      />
      {
        loading &&
        <div className="text-center">
          Loading...
        </div>
      }
      {/*{
          !loading &&
          !moreData &&
          <div className="text-center">
              No more entries to load.
          </div>
      }*/}
    </Col>
  );
}

import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import AddChildren from '../common/add-children';
import ListView from '../common/list-view';

import ItemDetails from './item-details';
import ImageCreateForm from '../image/image-create-form';
import ItemUpdateForm from './item-update-form';

import { fetchUserImages, fetchUserItems, fetchUserItemImages, fetchUserItemParents } from '../../utils/fetch-helpers'
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function ItemPage(){

  const user = useAuth();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const params = useParams();

  const [parents, setParents] = useState(null);
  const [items, setItems] = useState(null);
  const [item, setItem] = useState(null);
  const [updateModalShow, setUpdateModalShow] = useState(false);

  const [itemImages, setItemImages] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [createImageModalShow, setCreateImageModalShow] = useState(false);
  const [addImageModalShow, setAddImageModalShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async (signal) => {
    setItems(await fetchUserItems(user, signal));
  }, [user]);

  //----------------------------------------------------------------

  const fetchAllImages = useCallback( async (signal) => {
    setAllImages(await fetchUserImages(user, signal));
  }, [user]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async (signal) => {
    if (item) setParents(
      await fetchUserItemParents(user, item._id, signal)
    );
  }, [user, item]);

  //----------------------------------------------------------------

  const fetchItemImages = useCallback(async (signal) => {
    if (item && user){
      const fetchResult = await fetchUserItemImages(user, item._id, signal);
      if (fetchResult) {
        setItemImages(fetchResult.images)
      } else {
        setItemImages(null);
      };
    }
  },[user, item]);

  //----------------------------------------------------------------

  const setCurrentItem = useCallback(async (itemId) => {
    if (!itemId || !items) return;
    setLoading(true);
    const index = await items.findIndex((element) => element._id === itemId);
    if (index >= 0){
      items[index].index = index;
      setItem(items[index]);
    }
  }, [items]);

  //----------------------------------------------------------------

  const addImageToItem = useCallback(async (image) => {
    fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'add_images':[image._id]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        setItem(await response.json());
      } else {
        setToasts([...toasts, createToast(
          "Failed to add image to item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding images to item ${params.itemId}: ${error.message}`
      )]);
    });
  }, [toasts]);

  //----------------------------------------------------------------

  const addImagesToItem = useCallback(async (images) => {
    if (!item) return;

    await fetch(`/api/item/${item._id}`, {
      method: 'PUT',
      body: JSON.stringify({'add_images': images}),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok){
        setItem(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add images to item",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding images ${images} to item ${item._id}: ${error.message}`
      )]);
    });
  }, [item, toasts]);

  //----------------------------------------------------------------

  const removeImageFromItem = useCallback(async (imageId) => {

    await fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_images' : [imageId]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        setItem(await response.json());
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove image from item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing images from item ${params.itemId}: ${error.message}`
      )]);
    });
  }, [itemImages, toasts]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (items) setCurrentItem(params.itemId);
  }, [items, params.itemId]);

//----------------------------------------------------------------

  useEffect(() => {
    if (item){
      const abortController = new AbortController();
      const signal = abortController.signal;

      setLoading(true);

      (async () => {
        await fetchParents(signal);
        await fetchItemImages(signal);
        setLoading(false);
      })();

      return () => {
        // Cancel the request when the component unmounts
        abortController.abort();
      };
    }
  }, [item]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    fetchItems(signal);
    fetchAllImages(signal);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={items}
        currentObject={item}
        onClick={setCurrentItem}
        basePath={`/dashboard/item`}
      />

      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <ItemDetails item={item}/>
      }

      <Row className="mb-2 align-items-center" md={2}>
        <Col className="mb-2">
          <Link to={`/dashboard/items/`}>
            <Button className="text-nowrap w-100">
              Back to Items
            </Button>
          </Link>
        </Col>
        <Col className="mb-2">
          <Button
            className="text-nowrap w-100"
            onClick={() => setUpdateModalShow(true)}
            disabled={!item}
          >
            Edit
          </Button>
        </Col>
      </Row>

      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          { itemImages && itemImages.length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 m-auto text-start col">Images</Col>
                <Col className=" my-auto text-end col-2">Add</Col>
                <ButtonGroup className="col-md-3 text-center">
                  <Button
                    onClick={() => setCreateImageModalShow(true)}
                  >
                    New
                  </Button>
                  <Button
                    onClick={() => setAddImageModalShow(true)}
                  >
                    Existing
                  </Button>
                </ButtonGroup>
              </Row>
              <ListView
                list={itemImages}
                type="image"
                loading={loading}
                buttonCallback={removeImageFromItem}
                buttonIcon='dash'
                buttonRounded='1'
                isPrivate
              />
            </>
          }

          { parents && parents.collections.length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Parent Collections:</Col>
              </Row>

              <ListView
                list={parents.collections}
                type="collection"
                accessToken={user.token}
                isPrivate
              />
            </>
          }

          <ModalShell
            title="Add New Image"
            show={createImageModalShow}
            onHide={() => setCreateImageModalShow(false)}
            hideFooter={true}
          >
            <ImageCreateForm
              onCancel={() => setCreateImageModalShow(false)}
              onCreate={(image) => {
                addImageToItem(image);
                setCreateImageModalShow(false)
              }}
            />
          </ModalShell>

          <ModalShell
            key="addImageModal"
            title="Add Existing Images(s)"
            show={addImageModalShow}
            onHide={() => setAddImageModalShow(false)}
            hideFooter={true}
          >
            <AddChildren
              parent={item}
              childList={allImages && item ? allImages.filter(child => !item.images.includes(child._id)) : []}
              onAdd={(imageList) => {
                addImagesToItem(imageList);
                setAddImageModalShow(false);
              }}
              type="image"
            />
          </ModalShell>

          <ModalShell
            title="Update Item"
            show={updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            hideFooter={true}
          >
            <ItemUpdateForm
              item={item}
              onCancel={() => setUpdateModalShow(false)}
              onUpdate={fetchItems}
            />
          </ModalShell>
        </>
      }

      <Toaster toasts={toasts} />

    </>
  );
}

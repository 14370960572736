import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
import { PlasticCodes, ItemStates } from '../../enums';

import { useAuth } from "../../utils/auth-provider";
import PlasticCodeChecks from '../common/plastic-code-checks';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import ImageAdd from '../image/image-add';
import { formatTagsJSON } from '../../utils/utils';

//================================================================

export default function ItemCreateForm({onCreate, onCancel}) {

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const namePlaceholder = `New Item: ${new Date().toLocaleString()}`;
  const [name, setName] = useState("");
  const [imagesData, setImagesData] = useState([]);
  const [imageData, setImageData] = useState();
  const [imageInputs, setImageInputs] = useState([]);
  const [removeImageId, setRemoveImageId] = useState(null);
  const [itemDescription, setItemDescription] = useState("");
  const [envDescription, setEnvDescription] = useState("");
  const [tags, setTagsString] = useState("");
  const [working, setWorking] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(! ("geolocation" in navigator));
  const [geocoordinates, setGeocoordinates] = useState();
  const [plasticCodes, setPlasticCodes] = useState([]);
  const [itemState, setItemState] = useState('unset');
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const updatePlasticCodes = useCallback((code) => {
    console.log('updatePlasticCodes code:', code,'plasticCodes',plasticCodes);
    if (plasticCodes.includes(code)){
      console.log('remove by filtering');
      setPlasticCodes(plasticCodes.filter(e => e !== code));
    } else {
      console.log('add');
      setPlasticCodes([...plasticCodes, code]);
    }
  }, [plasticCodes])

  //----------------------------------------------------------------

  const updateGeocoordinates = useCallback(async () => {
    setDisableUpdate(true);
    await new Promise(async (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
    .then(({coords}) => {
      setGeocoordinates({
        'lat' : coords.latitude,
        'lon' : coords.longitude,
        'accuracy' : coords.accuracy
      });
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Failed to resolve geocoordinates.",
        error.message
      )]);
    });
    setDisableUpdate(false);
  });

  //----------------------------------------------------------------

  const addImageInput = useCallback(() => {
    const id = `${new Date().getTime()}`;
    setImageInputs([...imageInputs, <ImageAdd
      key={id}
      id={id}
      onCancel={setRemoveImageId}
      onChange={onChange}
      disabled={working}
    />]);
  }, [imageInputs]);

  const removeImageById = useCallback((id) => {
    setImagesData(imagesData.filter(e => e.id !== id));
    setImageInputs(imageInputs.filter(e => e.props.id !== id));
  },[imageInputs, imagesData]);

  useEffect(() => {
    if (imageInputs.length > 0){
      removeImageById(removeImageId);
    }
  },[removeImageId]);

  const onChange = useCallback(async (data) => {
    setImageData(data);
  });

  useEffect(() => {
    if (imageData){
      setImagesData([...imagesData.filter(e => e.id !== imageData.id), imageData]);
    }
  },[imageData]);

  //----------------------------------------------------------------

  const createImages = useCallback(async () => {
    const imagesToUpload = imagesData.filter(e => e.file !== undefined);
    const imageIds = await Promise.all(
      imagesToUpload.map(e => uploadImages(e))
    )
    .then((resolved) => {
      return resolved.reduce((result, e) => {
        if (e) result.push(e);
        return result;
      }, []);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching creating images for item: ${error.message}`
      )]);
    });

    return imageIds;

  },[imagesData]);

  //----------------------------------------------------------------

  const uploadImages = useCallback(async (data) => {
    setWorking(true);

    delete data['id'];

    const searchParamsString = new URLSearchParams(data).toString();

    const formData = new FormData();

    if(!data.file){
      setToasts([...toasts, createToast(
        "Required:",
        "Please select an image to upload."
      )]);
      setWorking(false);
      return;
    }

    formData.append('image', data.file);

    const imageId = await fetch(`/api/image/?${searchParamsString}`, {
      method : 'POST',
      body : formData,
      headers: {"Authorization": `Bearer ${user.token}`}
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        return result['_id'];
      } else {
        setToasts([...toasts, createToast(
          "Failed to create new image",
          `${response.status} - ${JSON.stringify(response), null, "\t"}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new image: ${error.message}`
      )]);
    });

    setWorking(false);
    return imageId;
  },[]);

  //----------------------------------------------------------------
  const createItemHandler = useCallback(async () => {

    setWorking(true);

    const imageIds = await createImages();

    const data = {
      'name': name || namePlaceholder,
      'item_description': itemDescription,
      'env_description': envDescription,
      'geocoordinates': geocoordinates,
      'plastic_codes': plasticCodes,
      'item_state': itemState,
      'tags': formatTagsJSON(tags)
    }

    if (imageIds.length > 0){
      data['images'] = imageIds;
    }

    await fetch('/api/item/', {
      method : 'POST',
      body : JSON.stringify(data),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onCreate(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to create new item",
          `${response.status} - ${JSON.stringify(response, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new item: ${error.message}`
      )]);
    });

    setWorking(false);
  }, [imagesData, name, itemDescription, envDescription, geocoordinates, plasticCodes, itemState, tags, onCreate]);

  //----------------------------------------------------------------

  return (
    <>
      <Form className="mb-4">

        <Form.Group className="mb-4">
          <InputGroup>
            <InputGroup.Text>Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={name}
                placeholder={namePlaceholder}
                onChange={(event) => setName(event.target.value)}
                disabled={working}
              />
            </InputGroup>
          <Form.Text className="text-muted">
            Give the Item a Name.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Item Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Write a short description of the item."
            onChange={(event) => setItemDescription(event.target.value)}
            disabled={working}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Environment Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Write a short description of where the item was found."
            onChange={(event) => setEnvDescription(event.target.value)}
            disabled={working}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          {imageInputs.length > 0 ? <Form.Label>Images</Form.Label> : ""}
          {imageInputs}
          <Col className="text-center">
            <Button
              onClick={addImageInput}
            >
              Add Image{imageInputs.length > 0 ? "" : "s"}
            </Button>
          </Col>
        </Form.Group>

        <Form.Group className="mb-4">
          <Row className="align-items-center mb-2">
            <Col className="text-start">Geocoordinates</Col>
            <Col className="text-end">
              <Button
                className="btn-sm"
                onClick={updateGeocoordinates}
                disabled={disableUpdate}
              >
                {disableUpdate ? <BasicSpinner text={"Resolving..."} /> : "Update"}
              </Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <Form.Control
                type="float"
                value={geocoordinates ? geocoordinates.lat : ""}
                readOnly
              />
              <Form.Text>Latitude:</Form.Text>
            </Col>
            <Col>
              <Form.Control
                type="float"
                value={geocoordinates ? geocoordinates.lon : "" }
                readOnly
              />
              <Form.Text>Longitute:</Form.Text>
            </Col>
            <Col>
              <Form.Control
                type="float"
                value={geocoordinates ? geocoordinates.accuracy : ""}
                readOnly
              />
              <Form.Text>Accuracy:</Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Plastic Codes</Form.Label>
          <PlasticCodeChecks
            selected={plasticCodes}
            onChange={updatePlasticCodes}
            disabled={working}
          />
        </Form.Group>

        <InputGroup className="mb-4">
          <InputGroup.Text>Item State</InputGroup.Text>
          <Form.Select
            onChange={(event) => setItemState(event.target.value)}
            disabled={working}
            defaultValue={itemState}
          >
            {Object.keys(ItemStates).map(key =>
              <option
                key={`itemState-option-${key}`}
                value={ItemStates[key]}
              >
                {ItemStates[key]}
              </option>
            )}
          </Form.Select>
        </InputGroup>

        <Form.Group className="mb-4">
          <InputGroup>
            <InputGroup.Text>Tags</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Enter tags"
              onChange={(event) => setTagsString(event.target.value)}
              disabled={working}
            />
          </InputGroup>
          <Form.Text className="text-muted">
            Tags separated by commas.
          </Form.Text>
        </Form.Group>

        <Row className="align-items-center">
          <Col className="text-start">
            <Button
              variant="secondary"
              onClick={onCancel}
              disabled={working}
            >
              Cancel
            </Button>
          </Col>
          <Col className="text-end">
            <Button
              className="text-nowrap"
              variant="primary"
              onClick={createItemHandler}
              disabled={working}
            >
              {working ? <BasicSpinner text="Adding New Item..." /> : "Add New Item"}
            </Button>
          </Col>
        </Row>
      </Form>

      <Toaster toasts={toasts} />
    </>
  );
}

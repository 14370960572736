import { Col, Row } from 'react-bootstrap';

//================================================================

export default function CollectionDetails({collection}){

  return(
    <Row className="mb-4" xs={1}>
      {collection ?
        <>
          <Col className="h4 text-truncate mb-2">
            Collection: {collection.name || "unnamed"}
          </Col>

          <Col className="text-truncate">
            ID: {collection._id || ""}
          </Col>

          <Col>
            Description: {collection.description}
          </Col>

          <Col>
            Tags: {collection.tags.join(", ")}
          </Col>

          <Col>
            Created On: {new Date(collection.created * 1000).toLocaleString() || "unavailable"}
          </Col>

          <Col>
            Last Updated: {new Date(collection.modified * 1000).toLocaleString() || "unavailable"}
          </Col>

          <Col>
            Type: {collection.type}
          </Col>

          <Col>
            Private: {collection.is_private? "true" : "false"}
          </Col>
        </>
        :
        <Col className="text-center">
          {"Unable to load Collection"}
        </Col>
      }
    </Row>
  );
}

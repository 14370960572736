import { useEffect, useState } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function Home() {

  const user = useAuth();
  const [userIsAuthorized, setUserIsAuthorized] = useState(false);

  useEffect(() => {
    if (!user) return;
    ( async () => {
      const authResult = await user.authCheck()
      if (!authResult) user.logOut();
      setUserIsAuthorized(authResult);
    })();
  },[user]);

  return (
    <>
      <Row className="mb-4">
        <Col className="h4">
          Collecting and Processing Environmental Plastics
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          This application is designed to be an aid in collecting and cataloging plastic waste.
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          It started with an idea inspired by Bruno Latour's "Down to Earth: Politics in the New Climatic Regime" to observe and document the earth beneath my very feet. I'm frequently out on my bike, along roads and waterways, through parks and neighborhoods. The amount of trash I see on my treks is astounding, and much of it is plastic, destined to find its way into the geologic record as plastiglomerates and other as of yet named geological formations.
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          I want to keep detailed records of the Forays I venture out on and the plastic Items I encounter. Many will be no condition to recycle, some may even be too hazardous to collect. Perhaps the Images and geocoordinates catalogged along with other details will be useful for the future. I have some ideas in mind as to what to do with all this data and will publish documentation of those developments here in the future.
        </Col>
      </Row>
        <Row className="align-children-center justify-content-center">
          <Col className="text-center" md={6}>
            { userIsAuthorized ?
              <Link to="dashboard">
                <Button className="btn w-100 mb-2">
                  View Your Dashboard
                </Button>
              </Link>
              :
              <>
                <Link to="login">
                  <Button className="btn w-100 mb-2">
                    Sign in
                  </Button>
                </Link>
                <Link to="sign-up">
                <Button className="btn w-100 mb-2">
                  Sign up
                </Button>
              </Link>
            </>
          }
        </Col>
      </Row>
      <Row className="align-children-center justify-content-center">
        <Col className="text-center mb-2" md={6}>
          <Link to="/public">
            <Button className="btn w-100">
              View Public Entries
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}

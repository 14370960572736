import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import BasicSpinner from '../common/basic-spinner';
import InfiniteScroll from '../common/infinite-scroll';

import { useAuth } from "../../utils/auth-provider";
import { fetchImageData } from "../../utils/utils";
import { fetchUserImages } from '../../utils/fetch-helpers'

import ImageCreateForm from '../image/image-create-form';

//================================================================

export default function ImagesPage(){

  const user = useAuth();

  const [images, setImages] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchImages = useCallback( async (signal) => {
    setImages( await fetchUserImages(user, signal));
  });

  //----------------------------------------------------------------

  const addImage = useCallback(async (image) => {
    setImages([...images, image]);
    setModalShow(false);
  }, [images]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      await fetchImages(signal);
      setLoading(false);
    })();

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Images:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setModalShow(true)}
              >
                Add New Image
              </Button>
            </Col>
          </Row>

          <InfiniteScroll
            data={images}
            type="image"
            isPrivate
          />
        </>
      }

      <ModalShell
        title="Add New Image"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addImage}
        />
      </ModalShell>
    </>
  );
}

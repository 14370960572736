import { useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/auth-provider";
import { useState, useEffect } from 'react';

//================================================================

export default function PrivateRoute() {
  // If the token has expired or if the user is not logged in,
  // user.authCheck() is false.
  // Expected behavior is to redirect to /login whenever an unauthorized session
  // attemps to access a PrivateRoute.

  const user = useAuth();
  const navigate = useNavigate();
  const [userIsAuthorized, setUserIsAuthorized] = useState(false);

  useEffect(() => {( async () => {
    const authResult = await user.authCheck()
    if (!authResult) {
      user.logOut();
      navigate('/');
    }
    setUserIsAuthorized(authResult);
  })();},[user]);

  return(
    <>{ userIsAuthorized && <Outlet /> }</>
  );
};

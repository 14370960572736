import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Form, Button, Spinner, Row } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function VerifyEmail() {

  const auth = useAuth();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [requestResult, setRequestResult] = useState();
  const [verificationResult, setVerificationResult] = useState();
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  //----------------------------------------------------------------

  const requestVerificationEmail = async (e) => {
    e.preventDefault();
    if (!email) return;
    setLoading(true);
    //request a new verification link to be sent to the provided email address
    setRequestResult(
      await fetch(
        `/api/account/request-verification?email=${email}`,
        {method:'POST'}
      )
      .then(async (response) => {
        if(response.ok) {
          //if the email is sent successfully
          setMessage('Success! Check your email for a new verification link.');
          return true;
        } else if (response.status === 500) {
          setMessage("A server error prevented your request from being processed.");
          setTimeout(() => {setMessage("")}, 5000);
          return false; 
        } else {
          const {detail} = await response.json();
          setMessage(detail);
          setTimeout(() => {setMessage("")}, 5000);
          return false; 
        }
      })
      .catch(error => console.error())
    );
    
    setLoading(false);
  };

  //----------------------------------------------------------------

  useEffect(() => {
    if (!token) return;
    (async () => {
      setVerificationResult(
        await fetch(
          `/api/account/verify-email?token=${token}`,
          {method:'POST'}
        )
        .then(async (response) => {
          return response.ok; 
        })
        .catch(error => console.error())
      );
    })();
  },[token]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (!searchParams) return;
    setToken(searchParams.get('token'));
  },[searchParams]);

  //----------------------------------------------------------------

  return(
    <Row className="align-items-center justify-content-center" xs={1}>
      <Col sm={6}>

        <h3>Email Verification</h3>

        { searchParams.get('result') === 'success' &&
          <Col className="mb-2">
            Thank you for signing up for an account! Please check your email address for a verification link.
          </Col>
        }

        { verificationResult ?
          <Col className="mb-2">
            Congratulations! You've successfully verified your email address.
          </Col>
          :
          <>
            <Col className="mb-2">
              Enter your email below to request a new verification token.
            </Col>
            <Form onSubmit={requestVerificationEmail} className="mb-2">
              <Form.Group as={Row} className="mb-2">
                <Form.Label column sm={3}>
                  Email
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    disabled={loading}
                  />
                </Col>
              </Form.Group>

              <Button
                className="w-100"
                type="submit"
                disabled={loading}
              >
                {loading ? <>
                  <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  size="sm"
                />{' '}
                Working...
                </> : "Submit"}
              </Button>
            </Form>
          </>
        }
        <Col className="text-center">
          {message}
        </Col>
      </Col>
    </Row>
  )
}

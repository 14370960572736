import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Form, Button, Spinner, Row } from 'react-bootstrap';
import { generateHMACSignature, generateSigningKey } from "../utils/hmac";

//================================================================

export default function SignUp() {
  // const [key, setKey] = useState();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  let navigate = useNavigate();

  //----------------------------------------------------------------

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      const data = {
        username: username,
        password: password,
        email: email
      };

      const payload = `${username}:${password}:${email}`;

      const key = await generateSigningKey();
      data.key = key.base64;

      const signature = await generateHMACSignature(key, payload);
      data.signature = signature;

      await fetch("/api/account/sign-up", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      })
      .then(async (response) => {
        if (response.ok){
          navigate("/verify-email?result=success");
        } else if (response.status === 400){
          setMessage("Invalid username or email address.");
        } else {
          setMessage("There was an error processing your request.");
        }
        setTimeout(() => {setMessage("")}, 5000);
      })
      .catch(error => console.error(error));
    }
    catch (error){
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }

  //----------------------------------------------------------------

  return(
    <Row className="align-items-center justify-content-center" xs={1}>
      <Col sm={6}>
        <Form onSubmit={submitForm}>

          <h3>New User Sign Up</h3>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              Username
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={e => setUsername(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              Password
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="password"
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              Email
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={e => setEmail(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Form.Group>

          <Button
            className="w-100"
            type="submit"
            disabled={loading}
          >
            {loading ? <>
              <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
            />{' '}
            Working...
            </> : "Submit"}
          </Button>
        </Form>
      </Col>
      <Col className="text-center">
        {message}
      </Col>
    </Row>
  )
}

import { useEffect, useState } from 'react';
import { Container, Nav, Navbar, Row, Offcanvas, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../../utils/auth-provider";

export default function MainNav(){

  const user = useAuth();
  const [userIsAuthorized, setUserIsAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    ( async () => {
      const authResult = await user.authCheck()
      if (!authResult) user.logOut();
      setUserIsAuthorized(authResult);
    })();
  },[user]);

  return(
    <Navbar expand='false' sticky="top" className="bg-body-tertiary mb-3">
      <Container className="px-4" fluid>
        <Navbar.Brand className="col">
          <Row className="my-2">
            <div className="fs-2 fw-light">
              The Plasticene
            </div>
            <div className="fs-6 fw-light">
              by <Link to="https://phillipstearns.com" target="_blank">Phillip David Stearns</Link>
            </div>
          </Row>
        </Navbar.Brand>
        <Navbar.Toggle className="rounded border-0" aria-controls={`offcanvasNavbar-expand-home`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-home`}
          aria-labelledby={`offcanvasNavbarLabel-expand-home`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-home`}>
              {/*Navigation*/}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 text-center">
              <Nav.Link href="/">
                <Button className="w-100" variant="outline-secondary">
                  Home
                </Button>
              </Nav.Link>
              <Nav.Link href="/public">
                <Button className="w-100" variant="outline-secondary">
                  Public Entries
                  </Button>
              </Nav.Link>
              { userIsAuthorized ?
                <>
                  <Nav.Link href="/dashboard">
                    <Button className="w-100" variant="outline-secondary">
                      Dashboard
                      </Button>
                  </Nav.Link>
                  <hr />
                  <Nav.Link href="/dashboard/collections">
                    <Button className="w-100" variant="outline-secondary">
                      Collections
                      </Button>
                  </Nav.Link>
                  <Nav.Link href="/dashboard/items">
                    <Button className="w-100" variant="outline-secondary">
                      Items
                      </Button>
                  </Nav.Link>
                  <Nav.Link href="/dashboard/images">
                    <Button className="w-100" variant="outline-secondary">
                      Images
                      </Button>
                  </Nav.Link>
                  <hr />
                  <Nav.Link
                    onClick={() => user.logOut()}
                  >
                    <Button className="w-100" variant="outline-secondary">
                      Sign Out
                    </Button>
                  </Nav.Link>
                </>
                :
                <>
                  <Nav.Link href="/login">
                    <Button className="w-100" variant="outline-secondary">
                      Sign In
                    </Button>
                  </Nav.Link>
                  <Nav.Link href="/sign-up">
                    <Button className="w-100" variant="outline-secondary">
                      Sign Up
                    </Button>
                  </Nav.Link>
                </>
              }
      
            </Nav>
           
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

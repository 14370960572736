import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import ListView from '../common/list-view';

import CollectionDetails from './collection-details';
import CollectionUpdateForm from './collection-update-form';
import CollectionCreateForm from './collection-create-form';
import ItemCreateForm from '../item/item-create-form';
import ImageCreateForm from '../image/image-create-form';
import AddChildren from '../common/add-children';

import { useAuth } from "../../utils/auth-provider";
import { fetchUserCollections, fetchUserItems, fetchUserImages, fetchUserCollectionChildren, fetchUserCollectionParents } from '../../utils/fetch-helpers';

//================================================================

export default function CollectionPage(){

  //----------------------------------------------------------------
  //Hooks and Variables

  const user = useAuth();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  };

  const params = useParams();

  const [parents, setParents] = useState(null);

  const [collection, setCollection] = useState(null);
  const [collections, setCollections] = useState(null);
  const [createCollectionModalShow, setCreateCollectionModalShow] = useState(false);
  const [addCollectionModalShow, setAddCollectionModalShow] = useState(false);

  const [items, setItems] = useState([]);
  const [createItemModalShow, setCreateItemModalShow] = useState(false);
  const [addItemModalShow, setAddItemModalShow] = useState(false);

  const [images, setImages] = useState([]);
  const [createImageModalShow, setCreateImageModalShow] = useState(false);
  const [addImageModalShow, setAddImageModalShow] = useState(false);

  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [children, setChildren] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------
  // Fetching and setting Collections

  const fetchCollections = useCallback( async (signal) => {
    setCollections( await fetchUserCollections(user, signal));
  }, [user]);

  //----------------------------------------------------------------
  // Fetch Items

  const fetchItems = useCallback( async (signal) => {
    setItems( await fetchUserItems(user, signal));
  }, [user]);

  //----------------------------------------------------------------
  // Fetching Images

  const fetchImages = useCallback( async (signal) => {
    setImages( await fetchUserImages(user, signal));
  }, [user]);

  //----------------------------------------------------------------
  // Select the current Collection by ID

  const setCurrentCollection = useCallback( async (collectionId) => {
    if (!collectionId || !collections) return;
    setLoading(true);
    const index = await collections.findIndex(candidate => candidate._id === collectionId);
    if (index >= 0){
      collections[index].index = index;
      setCollection(collections[index]);
    }
  }, [collections]);

  //----------------------------------------------------------------

  const addChildren = useCallback(async (children) => {
    if (!collection) return;

    await fetch(`/api/collection/${collection._id}`, {
      method: 'PUT',
      body: JSON.stringify({'add_children': children}),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok){
        setCollection(result);
        setAddItemModalShow(false);
        setCreateItemModalShow(false);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add children to collection",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding children ${children} to collection ${collection._id}: ${error.message}`
      )]);
    });
  }, [collection, toasts]);

  //----------------------------------------------------------------

  const removeChild = useCallback(async (childId) => {
    await fetch(`/api/collection/${collection._id}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_children' : [childId]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        const result = await response.json();
        setCollection(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove item from collection",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing item ${childId} from collection ${collection._id}: ${error.message}`
      )]);
    });
  }, [children, toasts, collection]);

  //----------------------------------------------------------------

  const fetchChildren = useCallback(async (signal) => {
    if (collection) setChildren(
      await fetchUserCollectionChildren(user, collection._id, signal)
    );
  });

  //----------------------------------------------------------------

  const fetchParents = useCallback(async (signal) => {
    if (collection) setParents(
      await fetchUserCollectionParents(user,collection._id, signal)
    );
  });

  //----------------------------------------------------------------

  useEffect(() => {
    if (collections) setCurrentCollection(params.collectionId);
  }, [collections, params.collectionId]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (collection){
      const abortController = new AbortController();
      const signal = abortController.signal;
    
      setLoading(true);

      (async () => {
        await fetchChildren(signal);
        await fetchParents(signal);
        setLoading(false);
      })();

      return () => {
        // Cancel the request when the component unmounts
        abortController.abort();
      };
    }
  }, [collection]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    fetchCollections(signal);
    fetchItems(signal);
    fetchImages(signal);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={collections}
        currentObject={collection}
        onClick={setCurrentCollection}
        basePath={'/dashboard/collection'}
      />

      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <CollectionDetails collection={collection}/>
      }

      <Row className="mb-2 align-children-center" md={2}>
        <Col className="mb-2">
          <Link to={'/dashboard/collections/'}>
            <Button className="text-nowrap w-100">
              Back to Collections
            </Button>
          </Link>
        </Col>
        <Col className="mb-2">
          <Button
            className="text-nowrap w-100"
            onClick={() => setUpdateModalShow(true)}
            disabled={!collection}
          >
            Edit
          </Button>
        </Col>
      </Row>

      <hr />

      { loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          <Row className="mb-3 align-children-center">
            <Col className="h4 my-0 text-start">In This Collection:</Col>
          </Row>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Collections</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button onClick={() => setCreateCollectionModalShow(true)}>
                New
              </Button>
              <Button onClick={() => setAddCollectionModalShow(true)}>
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          { children && children['collections'] &&
            <ListView
              list={children['collections']}
              type="collection"
              accessToken={user.token}
              isPrivate
            />
          }

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Items</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button onClick={() => setCreateItemModalShow(true)}>
                New
              </Button>
              <Button onClick={() => setAddItemModalShow(true)}>
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          { children && children['items'] &&
            <ListView
              list={children['items']}
              type="item"
              accessToken={user.token}
              isPrivate
            />
          }

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Images</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button onClick={() => setCreateImageModalShow(true)}>
                New
              </Button>
              <Button onClick={() => setAddImageModalShow(true)}>
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          {children && children['images'] &&
            <ListView
              list={children['images']}
              type="image"
              accessToken={user.token}
              isPrivate
            />
          }

          { parents && parents['collections'].length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Parent Collections:</Col>
              </Row>

              <ListView
                list={parents['collections']}
                type="collection"
                accessToken={user.token}
                isPrivate
              />
            </>
          }

          <ModalShell
            key="createCollectionModal"
            title="Add New Collection"
            show={createCollectionModalShow}
            onHide={() => setCreateCollectionModalShow(false)}
            hideFooter={true}
          >
            <CollectionCreateForm
              onCancel={() => setCreateCollectionModalShow(false)}
              onCreate={(child) => {
                addChildren([child._id]);
                setCreateCollectionModalShow(false);
              }}
            />
          </ModalShell>

          <ModalShell
            key="addCollectionModal"
            title="Add Existing Collection(s)"
            show={addCollectionModalShow}
            onHide={() => setAddCollectionModalShow(false)}
            hideFooter={true}
          >
            <AddChildren
              parent={collection}
              childList={collections && collection ? collections.filter(child => child._id !== collection._id && !collection.children.includes(child._id)) : []}
              onAdd={(children) => {
                addChildren(children);
                setAddCollectionModalShow(false);
              }}
              type="collection"
              unlinked
            />
          </ModalShell>

          <ModalShell
            key="createItemModal"
            title="Add New Item"
            show={createItemModalShow}
            onHide={() => setCreateItemModalShow(false)}
            hideFooter={true}
          >
            <ItemCreateForm
              onCancel={() => setCreateItemModalShow(false)}
              onCreate={(child) => {
                addChildren([child._id]);
                setCreateItemModalShow(false);
              }}
            />
          </ModalShell>

          <ModalShell
            key="addItemModal"
            title="Add Existing Items(s)"
            show={addItemModalShow}
            onHide={() => setAddItemModalShow(false)}
            hideFooter={true}
          >
            <AddChildren
              parent={collection}
              childList={items && collection ? items.filter(item => !collection.children.includes(item._id)) : []}
              onAdd={(childList) => {
                addChildren(childList);
                setAddItemModalShow(false);
              }}
              type="item"
              unlinked
            />
          </ModalShell>

          <ModalShell
            key="createImageModal"
            title="Add New Image"
            show={createImageModalShow}
            onHide={() => setCreateImageModalShow(false)}
            hideFooter={true}
          >
            <ImageCreateForm
              onCancel={() => setCreateImageModalShow(false)}
              onCreate={(child) => {
                addChildren([child._id]);
                setCreateImageModalShow(false);
              }}
            />
          </ModalShell>

          <ModalShell
            key="addImageModal"
            title="Add Existing Images(s)"
            show={addImageModalShow}
            onHide={() => setAddImageModalShow(false)}
            hideFooter={true}
          >
            <AddChildren
              parent={collection}
              childList={images && collection ? images.filter(image => !collection.children.includes(image._id)) : []}
              onAdd={(childList) => {
                addChildren(childList);
                setAddImageModalShow(false);
              }}
              type="image"
              unlinked
            />
          </ModalShell>

          <ModalShell
            key="updateModal"
            title="Update Collection"
            show={updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            hideFooter={true}
          >
            <CollectionUpdateForm
              collection={collection}
              onCancel={() => setUpdateModalShow(false)}
              onUpdate={fetchCollections}
            />
          </ModalShell>
        </>
      }

      <Toaster toasts={toasts} />

    </>
  );
}

import { Col, Row, Form, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import BasicSpinner from '../common/basic-spinner';
import ListView from '../common/list-view';
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function AddChildren({parent, childList, onAdd, type}) {

  const user = useAuth();

  //----------------------------------------------------------------
  // Hooks
  const [childrenToAdd, setChildrenToAdd] = useState([])
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------
  // Functions

  const updateChildrenToAdd = useCallback(async (checked, childId) => {
    setWorking(true);
      if (checked){
        setChildrenToAdd([...childrenToAdd, childId]);
      } else {
        setChildrenToAdd(childrenToAdd.filter(id => id !== childId))
      }
    setWorking(false);
  });

  //----------------------------------------------------------------

  return (

    <>
      { childList && childList.length > 0 ?
        <>
          <ListView
            onSelect={updateChildrenToAdd}
            list={childList}
            type={type}
            accessToken={user.token}
            isPrivate
          />
          <div className="text-center">
            <Button
              onClick={() => onAdd(childrenToAdd)}
            >
              Add Selected as Children
            </Button>
          </div>
        </>
        :
        <>No Children to Select</>
      }
    </>
  );
}

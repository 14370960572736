import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import BasicSpinner from '../common/basic-spinner';
import PreviousNext from '../common/previous-next';
import ListView from '../common/list-view';

import CollectionDetails from '../collection/collection-details';

import { fetchPublicCollections, fetchPublicCollectionParents, fetchPublicCollectionChildren } from '../../utils/fetch-helpers'
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function PublicCollectionPage(){

  //----------------------------------------------------------------
  //Hooks and Variables

  const user = useAuth();
  const params = useParams();

  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState(null);
  const [parents, setParents] = useState(null);
  const [children, setChildren] = useState(null);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------
  // Fetching and setting Collections

  const fetchCollections = useCallback( async (signal) => {
    setCollections(await fetchPublicCollections());
  }, []);

  //----------------------------------------------------------------

  const fetchChildren = useCallback(async (signal) => {
    if (collection) setChildren(
      await fetchPublicCollectionChildren(collection._id, signal)
    );
  }, [collection]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async (signal) => {
    if (collection) setParents(
      await fetchPublicCollectionParents(collection._id, signal)
    );
  });

  //----------------------------------------------------------------
  // Select the current Collection by ID

  const setCurrentCollection = useCallback( async (collectionId) => {
    if (!collectionId || !collections) return;
    setLoading(true);
    const index = await collections.findIndex(candidate => candidate._id === collectionId);
    if (index >= 0){
      collections[index].index = index;
      setCollection(collections[index]);
    }
  },[collections]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (collections) setCurrentCollection(params.collectionId);
  }, [collections, params.collectionId]);

  //----------------------------------------------------------------

  useEffect(() => {
    if (collection){
      const abortController = new AbortController();
      const signal = abortController.signal;

      setLoading(true);

      (async () => {
        await fetchChildren(signal);
        await fetchParents(signal);
        setLoading(false);
      })();

      return () => {
        // Cancel the request when the component unmounts
        abortController.abort();
      };
    }
  }, [collection]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    fetchCollections(signal);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={collections}
        currentObject={collection}
        onClick={setCurrentCollection}
        basePath={'/collection'}
      />

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <CollectionDetails collection={collection}/>
      }

      <Row className="mb-4 align-children-center justify-content-center" md={2}>
        <Col className="mb-2">
          <Link to={'/public#collections'}>
            <Button className="text-nowrap w-100">
              Back to Collections
            </Button>
          </Link>
        </Col>
        {user && collection &&
          <Col className="mb-2">
            <Link
              to={`/dashboard/collection/${collection._id}`}
            >
              <Button
                className="text-nowrap w-100"
              >
                View Your Collection
              </Button>
            </Link>
          </Col>
        }
      </Row>

      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          {/* Children Section */}
          { children &&
            ( children['collections'].length > 0
            || children['items'].length > 0
            || children['images'].length > 0 ) &&
            <>
              <hr />

              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Children</Col>
              </Row>

              <ListView
                list={children['collections']}
                name="Collections"
                type="collection"
              />

              <ListView
                list={children['items']}
                name="Items"
                type="item"
              />

              <ListView
                list={children['images']}
                name="Images"
                type="image"
              />

            </>
          }
          {/* End of Children Section */}

          {/*Parent Section*/}
          { parents && parents['collections'].length > 0 &&
            <>
              <hr />
              <Row className="mb-3 align-children-center">
                <Col className="h4 my-0 text-start">Parents</Col>
              </Row>

              <ListView
                list={parents['collections']}
                name="Collections"
                type="collection"
              />
            </>
          }
          {/* End of Parent Section */}
        </>
      }
    </>
  );
}

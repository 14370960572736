import { Image, Col, Row} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function ImageDetails({image}){

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  },[image]);


  //----------------------------------------------------------------

  return(
    <>
      { image ?
      <>
          <Row className="mb-2">
            <Col className="h4 text-truncate mb-2 text-center" xl>
              Image: {image.name || "unnamed"}
            </Col>
          </Row>
          <Row className="mb-4" sm={2}>
            <Col className="position-relative mb-2 p-0 text-center" xs={12}>
              <Col
                className="position-absolute h-100 w-100"
                hidden={!loading}
                style={{zIndex: 2}}
              >
                <BasicSpinner
                  className="position-absolute top-50 start-50 translate-middle"
                  text="Image loading..."
                />
              </Col>
              <Col
                className="position-absolute h-100 w-100 bg-light opacity-75"
                hidden={!loading}
                style={{zIndex: 1}}
              />
              <Col
                className='h-100 d-flex justify-content-center'
              >
                <Image
                  src={`/static/uploads/${image.owner_id}/${image.sha256}.${image.format.split('/')[1]}`}
                  onLoad={() => setLoading(false)}
                  onError={() => setLoading(false)}
                  style={{zIndex: 0}}
                  fluid
                />
              </Col>
            </Col>
            <Col xs={12}>
              <Col className="text-truncate">
                ID: {image._id}
              </Col>

              <Col className="text-truncate">
                Uploaded Filename: {image.filename || "unset"}
              </Col>

              <Col className="text-truncate">
                SHA256: {image.sha256 || ""}
              </Col>

              <Col className="text-truncate">
                Format: {image.format || ""}
              </Col>

              <Col className="text-truncate">
                Size (bytes): {image.size || ""}
              </Col>

              <Col>
                Description: {image.description || ""}
              </Col>

              <Col className="text-truncate">
                Type: {image.image_type || ""}
              </Col>

              <Col>
                Tags: {image.tags.join(", ") || ""}
              </Col>

              <Col className="text-truncate">
                Added On: {new Date(image.created * 1000).toLocaleString() || "unavailable"}
              </Col>

              <Col className="text-truncate">
                Last Updated: {new Date(image.modified * 1000).toLocaleString() || "unavailable"}
              </Col>

              <Col>
                Private: {image.is_private ? "true" : "false"}
              </Col>
            </Col>
          </Row>
        </>
        :
        <Col className="text-center"  xs={12}>
          {"Unable to load Image"}
        </Col>
      }
    </>
  );
}

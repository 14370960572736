import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import BasicSpinner from '../common/basic-spinner';
import InfiniteScroll from '../common/infinite-scroll';

import ImageCreateForm from '../item/item-create-form';

import { useAuth } from "../../utils/auth-provider";
import { fetchUserItems } from '../../utils/fetch-helpers'

//================================================================

export default function ItemsPage(){

  const user = useAuth();

  const [items, setItems] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  //----------------------------------------------------------------

  const fetchItems = useCallback( async (signal) => {
    setItems( await fetchUserItems(user, signal));
  });

  //----------------------------------------------------------------

  const addItem = useCallback(async (item) => {
    setItems([...items, item]);
    setModalShow(false);
  }, [items]);

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchItems(signal);
    setLoading(false);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      {loading ?
        <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
        :
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Items:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setModalShow(true)}
              >
                Add New Item
              </Button>
            </Col>
          </Row>

          <InfiniteScroll
            data={items}
            type="item"
            isPrivate
          />
        </>
      }

      <ModalShell
        title="Add New Image"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addItem}
        />
      </ModalShell>
    </>
  );
}

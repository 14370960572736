import { Link } from 'react-router-dom';
import { Col, Row, Form, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import { fetchImageData } from "../../utils/fetch-helpers";
import { useAuth } from "../../utils/auth-provider";
import RemoveButton from './remove-button';
import BasicSpinner from './basic-spinner';

//================================================================

export default function ListRow({ onSelect, type, object, buttonCallback, buttonIcon, buttonRounded, isPrivate, unlinked}){

  const user = useAuth();
  
  const [loading, setLoading] = useState(true);
  const linkTo  = unlinked ? null : `/${isPrivate ? 'dashboard/' : ''}${type}/${object._id}`;
  const [imageData, setImageData] = useState(null);
  
  //----------------------------------------------------------------

  const downloadImage = async (imageId, signal) => {
    const data = await fetchImageData(imageId, 50, 50, isPrivate ? user.token : null, signal)
    .catch(error => console.error(error));
    setImageData(data);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async() => {// If the object contains a featured image value, download that image
      if (object.featured_image) {
        await downloadImage(object.featured_image, signal);
      } else if (type === 'image'){
        await downloadImage(object._id, signal);
      }
      setLoading(false);
    })();

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };

  }, [object, type]);

  //----------------------------------------------------------------
  return(
    <Row className="mb-1 align-items-center rounded border border-1 py-2">
      { onSelect ?
        <Col className="col-1">
          <Form.Check
            type="checkbox"
            id={`select-${object._id}`}
            onChange={(event) => {onSelect(event.target.checked, object._id)}}
          />
        </Col>
        :
        <></>
      }
      { imageData !== null || loading ?
        <Col className="text-center px-1 mx-1" md={1} xs={2}>
          { loading ?
            <BasicSpinner />
            :
            <>
              {linkTo ?
                <Link
                  key={`image-link-${object._id}`}
                  to={linkTo}
                >
                  { imageData ? <Image src={imageData} fluid /> : <></> }
                </Link>
                :
                <>
                  { imageData ? <Image src={imageData} fluid /> : <></> }
                </>
              }
            </>
          }
        </Col>
        :
        <></>
      }

      <Col className="text-start text-truncate">
        {linkTo ?
          <Link
            key={`image-link-${object._id}`}
            to={linkTo}
          >
            {object.name}
          </Link>
          :
          <>{object.name}</>
        }
      </Col>
      { buttonCallback ?
        <Col className="text-end">
          <RemoveButton
            key={`remove-button-row-${object._id}`}
            objectId={object._id}
            id={`remove-button-${object._id}`}
            onClick={buttonCallback}
            buttonIcon={buttonIcon}
            buttonRounded={buttonRounded}
          />
        </Col>
        :
        <></>
      }
    </Row>
  );
}

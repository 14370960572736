import { Link } from 'react-router-dom';
import { Button, ToggleButton } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function RemoveButton({objectId, id, onClick, buttonIcon, buttonRounded}){

  const icon = buttonIcon || "dash";
  const rounded = buttonRounded ? `rounded-${buttonRounded}` : '';

  const [working, setWorking] = useState(false);
  const [enabled, setEnabled] = useState(false);

  //----------------------------------------------------------------

  const handleButtonClick = useCallback(async () => {
    setWorking(true);
    await onClick(objectId);
    setWorking(false);
  }, [onClick, objectId]);

  //----------------------------------------------------------------

  return(
    <>
      <ToggleButton
        disabled={onClick ? false : true}
        id={`removeToggle-${id}`}
        type="checkbox"
        variant={enabled ? "outline-danger" : "outline-secondary" }
        checked={enabled}
        value="1"
        onChange={(e) => setEnabled(e.currentTarget.checked)}
        className={`btn-sm ${rounded}`}
      >
        {enabled ? "Cancel" : <i className={`bi bi-${icon}`} /> }
      </ToggleButton>
      {' '}
      <Button
        className={`btn-sm ${rounded}`}
        variant="danger"
        onClick={onClick ? handleButtonClick : () => setEnabled(false)}
        hidden={!enabled}
        disabled={!onClick || !enabled || working}
      >
        <span className="visually-hidden">Delete</span>
        {working ?
          <BasicSpinner />
          :
          <i className={`bi bi-${icon}`} />
        }
      </Button>
    </>
  );
}

import { Link } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

export default function PreviousNext({objects, currentObject, onClick, basePath}){

  const [nextObjectId, setNextObjectId] = useState('');
  const [prevObjectId, setPrevObjectId] = useState('');

  const handleClick = useCallback((objectId) => {
    setPrevObjectId('');
    setNextObjectId('');
    onClick(objectId);
  }, [onClick]);

  useEffect(() => {

    if(currentObject && objects[currentObject.index - 1]){
      setPrevObjectId(objects[currentObject.index - 1]._id);
    }

    if(currentObject && objects[currentObject.index + 1]){
      setNextObjectId(objects[currentObject.index + 1]._id);
    }

  }, [currentObject, objects]);

  return(
    <Row className="my-2 mb-4 align-items-center" xs={2}>
      <Col>
        <Link
          to={`${basePath}/${prevObjectId}`}
          style={{pointerEvents: prevObjectId ? '' : 'none'}}
          onClick={() => handleClick(prevObjectId)}
        >
          <Button className="w-100" disabled={!prevObjectId}>
            Previous
          </Button>
        </Link>
      </Col>
      <Col>
        <Link
          to={`${basePath}/${nextObjectId}`}
          style={{pointerEvents: nextObjectId ? '' : 'none'}}
          onClick={() => handleClick(nextObjectId)}
        >
          <Button className="w-100" disabled={!nextObjectId}>
            Next
          </Button>
        </Link>
      </Col>
    </Row>
  );
}

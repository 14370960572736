import { Button, Col, Row, Tab , Tabs } from 'react-bootstrap';
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react';

import BasicSpinner from '../components/common/basic-spinner';
import InfiniteScroll from '../components/common/infinite-scroll';

import { useAuth } from "../utils/auth-provider";
import { fetchUserCollections, fetchUserItems, fetchUserImages } from '../utils/fetch-helpers';

//================================================================

export default function Dashboard() {

  const user = useAuth();

  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [key, setKey] = useState('collections');
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchCollections = async (user, signal) => {
    setCollections(await fetchUserCollections(user, signal));
  };

  //----------------------------------------------------------------

  const fetchItems = async (user, signal) => {
    setItems(await fetchUserItems(user, signal));
  };

  //----------------------------------------------------------------

  const fetchImages = async (user, signal) => {
    setImages(await fetchUserImages(user, signal));
  };

  //----------------------------------------------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    fetchCollections(user, signal);
    fetchItems(user, signal);
    fetchImages(user, signal);
    setLoading(false);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };

  }, []);

  //----------------------------------------------------------------

  return (
    <>
      <Row className="mb-2">
        <Col className="h4">
          Your Entries
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        {/*Tab content for Collections*/}
        <Tab eventKey="collections" title="Collections">
          <Row className="mb-2 align-children-center justify-content-center">
            <Col className="h5 my-0 col-md-6">
              <Link
                to="/dashboard/collections/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                <Button className="text-nowrap btn w-100">
                  Go To Collections
                </Button>
              </Link>
            </Col>
          </Row>

          {loading ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={collections}
              type="collection"
              isPrivate
            />
          }
        </Tab>

        {/*Tab for Items*/}
        <Tab eventKey="items" title="Items">
          <Row className="mb-2 align-children-center justify-content-center">
            <Col className="h5 my-0 col-md-6">
              <Link
                to="/dashboard/items/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                <Button className="text-nowrap btn w-100">
                  Go To Items
                </Button>
              </Link>
            </Col>
          </Row>

          {loading ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={items}
              type="item"
              isPrivate
            />
          }
        </Tab>

        {/*Tab for Images*/}
        <Tab eventKey="images" title="Images">
          <Row className="mb-2 align-children-center justify-content-center">
            <Col className="h5 my-0 col-md-6">
              <Link
                to="/dashboard/images/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                <Button className="text-nowrap btn w-100">
                  Go To Images
                </Button>
              </Link>
            </Col>
          </Row>

          {loading ?
            <BasicSpinner className="mb-4 col text-center" text={'Loading...'} />
            :
            <InfiniteScroll
              data={images}
              type="image"
              isPrivate
            />
          }
        </Tab>
      </Tabs>
    </>
  );
}

// https://stackoverflow.com/questions/49081874/i-have-to-hash-a-text-with-hmac-sha256-in-javascript
export const HMAC = async (secretKey, message, algorithm = "SHA-256") => {
  // Convert the message and secretKey to Uint8Array
  const encoder = new TextEncoder();
  const messageUint8Array = encoder.encode(message);
  const keyUint8Array = encoder.encode(secretKey);

  // Import the secretKey as a CryptoKey
  const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyUint8Array,
      { name: "HMAC", hash: algorithm },
      false,
      ["sign"]
  );

  // Sign the message with HMAC and the CryptoKey
  const signature = await window.crypto.subtle.sign(
      "HMAC",
      cryptoKey,
      messageUint8Array
  );

  // Convert the signature ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(signature));
  const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

  return hashHex;
}

export const generateJWK = async () => {
  const cryptoKey = await window.crypto.subtle.generateKey(
    {
      name: "HMAC",
      hash: { name: "SHA-512" },
    },
    true,
    ["sign", "verify"],
  );

  const extractedKey = await window.crypto.subtle.exportKey('jwk', cryptoKey);
  const key = extractedKey.k;
  return key;
}

export async function generateHMACSignature(key, message) {
    // Encode the key and message as Uint8Array
    const encoder = new TextEncoder();
    const messageData = encoder.encode(message);

    // Generate HMAC
    const signature = await window.crypto.subtle.sign(
        'HMAC',
        key,
        messageData
    );
    // Convert signature to hex format
    return Array.from(
      new Uint8Array(signature)
    ).map(b => ('00' + b.toString(16)).slice(-2)).join('');
}

export async function generateSigningKey(){
  // generate signing key locally
  const key = await window.crypto.subtle.generateKey(
    { name: "HMAC", hash: { name: "SHA-256" } },
    true,
    ["sign", "verify"]
  );
  const exportedKey = await window.crypto.subtle.exportKey("raw", key);
  var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(exportedKey)));
  key.base64 = base64String;
  return key;
}

import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
import { ImageTypes } from '../../enums';
import { formatTagsMultipart } from '../../utils/utils';

import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ImageAdd({onCancel, onChange, id, disabled}) {
  const [name, setName] = useState("");
  const [imageType, setImageType] = useState(ImageTypes.UNSET);
  const [file, setFile] = useState();
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  useEffect(() => {
    const data = {
      'id': id,
      'name': name,
      'file': file,
      'image_type': imageType
    }
    onChange(data);
  }, [name, file, imageType]);

  const handleFileSelection = useCallback(async (event) => {
    const selection = event.target.files[0];
    if(selection && (selection.type === "image/png" || selection.type === "image/jpeg")){
      setName(selection.name);
      setFile(selection);
    } else {
      setName("");
      setFile(undefined);
      setToasts([...toasts, createToast(
        "File type not accepted:",
        "Please select a .png or .jpg"
      )]);
    }
  });

  //----------------------------------------------------------------

  return (
    <div className="mb-2">
      <Row className="align-items-center mb-1">

        <Form.Group controlId="formFile" as="div" className="col-11">
          <Form.Control
            type="file"
            onChange={handleFileSelection}
          />
        </Form.Group>

        <Col className="col-1">
          <Button
            size="sm"
            className="border-0 rounded d-flex justify-content-center"
            variant="outline-secondary"
            onClick={() => onCancel(id)}
            disabled={disabled}
          >
            <i className="bi bi-x-lg"></i>
          </Button>
        </Col>
      </Row>

      <Row className="align-items-center g-2">
        <InputGroup className="col">
          <InputGroup.Text>Image Type</InputGroup.Text>
          <Form.Select
            onChange={(event) => setImageType(event.target.value)}
            disabled={disabled}
            defaultValue={imageType}
          >
            {Object.keys(ImageTypes).map(key =>
              <option
                key={`itemType-option-${key}`}
                value={ImageTypes[key]}
              >
                {ImageTypes[key]}
              </option>
            )}
          </Form.Select>
        </InputGroup>
      </Row>

      <Toaster toasts={toasts} />

    </div>
  );
}

import { Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlasticCodes } from '../../enums';
import { useCallback } from 'react';

//================================================================

export default function ItemDetails({item}){

  // const geocoordinates = item.geocoordinates || null;

  //----------------------------------------------------------------

  const displayPlasticCode = useCallback(() => {

    const keys = item.plastic_codes.map((plasticCode) => {
      const key = Object.keys(PlasticCodes).find(key => PlasticCodes[key]['value'] === plasticCode);
      if (key){
        return PlasticCodes[key]['name'];
      } else {
        return `BAD VALUE ${item.plastic_code}`;
      }
    });

    return keys;
  });

  //----------------------------------------------------------------

  return(
    <>
      { item ?
        <Row className="mb-4" xs={1}>
          <Col className="h4 text-truncate mb-2">
            Item: {item.name || "unnamed"}
          </Col>

          <Col className="text-truncate">
            ID: {item._id || ""}
          </Col>

          <Col>
            Item Description: {item.item_description || ""}
          </Col>

          <Col>
            Environment Description: {item.env_description || ""}
          </Col>

          <Col>
            Geocoordinates:{' '}
            {item.geocoordinates ? <>
              <Link
                to={`https://www.openstreetmap.org/?mlat=${item.geocoordinates.lat}&mlon=${item.geocoordinates.lon}&zoom=${process.env.REACT_APP_OSM_ZOOM}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                View on OpenStreetMap
              </Link>
              {Object.entries(item.geocoordinates).map(([key,value]) =>
                <li key={`geocoordinates-${key}-${value}`}>
                  {key}: {value}
                </li>
              )}
            </> : "Not captured."}
          </Col>

          <Col>
            Plastic Codes: {displayPlasticCode().join(", ") || "None assigned."}
          </Col>

          <Col>
            State: {item.item_state || ""}
          </Col>

          <Col>
            Tags: {item.tags.join(", ") || ""}
          </Col>

          <Col>
            Added On: {new Date(item.created * 1000).toLocaleString() || ""}
          </Col>

          <Col>
            Last Updated: {new Date(item.modified * 1000).toLocaleString() || ""}
          </Col>

          <Col>
            Private: {item.is_private ? "true" : "false"}
          </Col>
      </Row>
      :
      <Col className="text-center">
        {"Unable to load Item"}
      </Col>
    }
  </>
  );
}

import { Col, Row} from 'react-bootstrap';

import ListRow from '../common/list-row';

//================================================================

export default function ListView({list, onSelect, name, type, buttonCallback, buttonIcon, buttonRounded, isPrivate}){

  
  
  //----------------------------------------------------------------
  //Hooks and Variables

  return(
    <>
      { list && list.length > 0 ?
        <>
          {name ?
            <Row className="mb-3 align-children-center">
              <Col className="h5 m-auto text-start col">{name}</Col>
            </Row>
            :
            <></>
          }
          <div className="mb-3">
            {list.map(item => <ListRow
              key={`${isPrivate ? 'private' : 'public'}-${name||type}-listRow-${item._id}`}
              type={type}
              object={item}
              onSelect={onSelect}
              isPrivate={isPrivate}
              buttonCallback={buttonCallback}
              buttonIcon={buttonIcon}
              buttonRounded={buttonRounded}
            />)}
          </div>
        </>
        :
        <></>
      }
    </>
  );
}
